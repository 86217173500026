import * as React from "react"

const GithubFinder = () => {
  React.useEffect(() => {
    // navigate('/');

    window.location = "/";
  }, []);

  return null;
}
export default GithubFinder;